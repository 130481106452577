


















import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  AttachmentHostType,
  DonatedAmountConfigCreateOrUpdateDto,
  DonatedAmountConfigSourceType,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import MoneyForm from "@/components/MoneyForm/index.vue";

@Component({
  components: {
    MoneyForm,
  },
})
export default class MoneyFormEdit extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId!: number;

  donateType: DonatedAmountConfigSourceType =
    DonatedAmountConfigSourceType.Fund;

  get hostTypeFile() {
    return AttachmentHostType.Fund;
  }

  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
    }
  }

  cancel() {
    this.$router.back();
  }
}
