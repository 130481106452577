

































































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  AttachmentHostType,
  DonatedAmountConfigCreateOrUpdateDto,
  DonatedAmountConfigSourceType,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";

@Component({
  components: {
    SimpleUploadImage,
  },
})
export default class MoneyForm extends Vue {
  @Prop({ required: true })
  dataId!: number;

  @Prop({ required: true })
  hostType!: AttachmentHostType;

  @Prop({ required: true })
  sourceType!: DonatedAmountConfigSourceType;

  formDonate: DonatedAmountConfigCreateOrUpdateDto = {
    id: 0,
    name: undefined,
    amount: undefined,
  };

  isShow = false;
  donateItems: any = [];

  created() {
    this.fetchDonateItems();
  }

  fetchDonateItems() {
    api.donatedAmountConfig
      .getAll({
        sourceId: String(this.dataId),
        sourceType: this.sourceType,
      })
      .then((res) => {
        this.donateItems = res.items;
      });
  }

  addRow() {
    this.formDonate.id = 0;
    this.formDonate.name = undefined;
    this.formDonate.amount = undefined;
    this.formDonate.imageUrl = undefined;
    this.isShow = true;
  }

  deleteRow(id: number) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.donatedAmountConfig.delete({ id: id }).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.fetchDonateItems();
      });
    });
  }

  editRow(id: number) {
    this.isShow = true;
    this.formDonate.id = id;
    api.donatedAmountConfig.get({ id: id }).then((res) => {
      this.formDonate = { ...res };
    });
  }

  async saveConfig() {
    if (!this.formDonate.name) {
      this.$message.error("请填写标题");
      return;
    }
    if (this.formDonate.name && this.formDonate.name.length > 8) {
      this.$message.error("标题最大长度8个字");
      return;
    }
    if (!this.formDonate.amount) {
      this.$message.error("请填写金额");
      return;
    }
    if (this.formDonate.amount <= 0) {
      this.$message.error("金额必须大于0");
      return;
    }

    this.formDonate.sourceType = this.sourceType;
    this.formDonate.sourceId = String(this.dataId);
    if (this.formDonate.id) {
      await api.donatedAmountConfig.update({
        body: this.formDonate,
      });
    } else {
      await api.donatedAmountConfig.create({
        body: this.formDonate,
      });
    }

    this.$message.success("操作成功");
    this.isShow = false;
    this.formDonate.id = 0;
    this.formDonate.name = undefined;
    this.formDonate.amount = undefined;
    this.formDonate.imageUrl = undefined;
    this.fetchDonateItems();
  }
}
